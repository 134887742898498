import React from 'react'
import styles from './simple.module.css'
import Img from 'gatsby-image'
export const SimpleGallery = (props) => (
  <div className={styles.container}>
    {props.images.map(i=>(
      <div className={styles.item}>
        <Img fixed={i.fixed}/>
      </div>
    ))}
    
  </div>
)
