import React from 'react'
import BackgroundImage from 'gatsby-background-image'
import {navigate} from "gatsby"
import Img from 'gatsby-image'
import bannerStyles from './banner.module.css'

export const Banner = (props)=>(
  <div
    className={bannerStyles.banner}
    onClick={props.onClick}
  >
     
    <BackgroundImage
      tag="div"
      className={bannerStyles.background}
      fluid={props.image}
      onClick={props.mobileClick}
    >
      <div className={`container ${bannerStyles.content}`}>

        { props.tags && props.tags.length>0 &&
          <div className={bannerStyles.tags}>
            { props.tags.map(tag=><Tag key={tag.url} {...tag}/>)}
          </div>
        }
        <span className={bannerStyles.title} dangerouslySetInnerHTML={{__html:props.title}}/>
      </div>
    </BackgroundImage>
  </div>
)
