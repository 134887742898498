import React from 'react';
import {graphql} from 'gatsby';
import Layout from 'components/layout'
import {Content, Banner} from 'components/crops'

const SingleCropsTemplate = ({data, ...props}) => (
  <Layout
    articleId={`${data.page.type}-${data.page.wordpress_id}`}
    articleClassName={`${data.page.classes}`}
    context = {props.pageContext}
    location = {props.location}
  >
    <header className="entry-header md:px-0 px-5 mb-16">
        <Banner title={data.page.title} image={data.page.featured_media}/>
    </header>
    <div className="entry">
      <div className="entry-content container px-5 mt-10" >
        <Content {...data.page}/>
  </div>
    </div>
  </Layout>
);

export default SingleCropsTemplate

export const SingleCropsQuery = graphql `
query SingleCropsById($id: String!){
  page:wordpressWpCrops(id:{eq:$id}){
    title
    content
    path
    type
    date
    wordpress_id
    featured_media {
     localFile {
       ...PageBannerImages
     }
    }
    classes
    gallery {
      localFile{
        ...GalleryThumbnail
      }
    }
    documents {
      title
      link {
        target
        url
      }
      content:description
      isDownloadable
      thumbnail {
        localFile {
          ...ContentCardImages
        }
      }
    }
  }
}
`

