import React from 'react'
import BackgroundImage from 'gatsby-background-image'
import {navigate} from "gatsby"
import Img from 'gatsby-image'
import cardStyles from './card.module.css'

export const Card = React.forwardRef((props, ref)=>{
  return(
    <div
      className={cardStyles.card}
      onClick={props.onClick}
      ref={ref}
    >
      <BackgroundImage
        tag="div"
        className={cardStyles.background}
        fluid={props.image}
        onClick={props.mobileClick}
      >
        <div className={cardStyles.content}>

          { props.tags && props.tags.length>0 &&
            <div className={cardStyles.tags}>
              { props.tags.map(tag=><Tag key={tag.url} {...tag}/>)}
            </div>
          }
          <span className={cardStyles.title} dangerouslySetInnerHTML={{__html:props.title}}/>
        </div>
      </BackgroundImage>
    </div>
  )
})
