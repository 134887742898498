import React from 'react'
import {Img} from 'gatsby-image'
import MediaQuery from 'react-responsive'
import {Card} from './parts/card';
import {Banner} from 'components/banners';

export const CropBanner = (props) => {
  
  return (
    
    <>
      <MediaQuery query="(min-width: 769px)">
        <Banner
          image={props.image.localFile.childImageSharp.desktop}
          title={props.title}
        />
      </MediaQuery>
      <MediaQuery query="(max-width: 768px)">
        <div className="flex justify-center">
        <Card
          image={props.image.localFile.childImageSharp.mobile}
          title={props.title}
      />
        </div>
      </MediaQuery>
    </>
  )
}
