import React from 'react'
import ContentCard from 'components/cards'
import CropStyles from './single.module.css'
import {SimpleGallery} from 'components/gallery'
export const CropContent = (props) => (
  <>
    <div className={CropStyles.container} dangerouslySetInnerHTML={{__html: props.content}}/>
    <footer className={`entry-footer ${CropStyles.footer}`}>
      {props.gallery && props.gallery.length &&
      <div className="mx-auto">
        <SimpleGallery images={props.gallery.map(i=>i.localFile.thumbnail)}/>
      </div>
      }
      <div className="mx-auto">
      {props.documents && props.documents.length >0 &&
       props.documents.map((doc, index)=>(
         <div key={doc.id} className="mb-5">
           <ContentCard {...doc} showThumbnail={false}/>
         </div>))
      }
      </div>
    </footer>
  </>
)
