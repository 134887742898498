import React from 'react'
import BackgroundImage from 'gatsby-background-image'
import {navigate} from "gatsby"
import Img from 'gatsby-image'
import bannerStyles from './banner.module.css'
import cardStyles from './card.module.css'
export const BannerWithCard = (props)=>(
  <div
    className={bannerStyles.banner}
    onClick={props.onClick}
  >
     
    <BackgroundImage
      tag="div"
      className={bannerStyles.background}
      fluid={props.image}
      onClick={props.mobileClick}
    />
    <div className={bannerStyles.cardContainer}>
      <div className={cardStyles.card}>
        <div className={cardStyles.tag}>
          <span dangerouslySetInnerHTML={{__html:props.type}}/>
        </div>
        <div className={cardStyles.title}>
          <h1 dangerouslySetInnerHTML={{__html:props.title}}/>
        </div>
        <div className={cardStyles.excerpt} dangerouslySetInnerHTML={{__html:props.excerpt}} />

      { props.tags && props.tags.length>0 &&
        <div className={bannerStyles.tags}>
          { props.tags.map(tag=><Tag key={tag.url} {...tag}/>)}
        </div>
      }
      </div>
      
    </div>
  </div>
)
